import React, { useState, useEffect, useMemo } from 'react';
import '../admin/TourEdit.css';
import {
  Table,
  TextInput,
  Button,
  Heading,
  Pane,
  toaster,
  FilePicker,
  SelectMenu,
  Textarea,
  TagInput,
  Dialog,
} from 'evergreen-ui';

function TourEdit() {
  const [tours, setTours] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [editTourId, setEditTourId] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedSafariType, setSelectedSafariType] = useState(null);
  const [isDialogShown, setIsDialogShown] = useState(false);

  const [formData, setFormData] = useState({
    country: '',
    safari_type: '',
    destination_title: '',
    destination_name: '',
    duration: '',
    price: '',
    image_preview: null,
    info_overview: '',
    info_highlight: '',
    departure: '',
    included: [],
    excluded: [],
    travel_plan_overview: '',
    travel_plan_day_title_1: '',
    travel_plan_day_description_1: '',
    travel_plan_day_title_2: '',
    travel_plan_day_description_2: '',
    travel_plan_day_title_3: '',
    travel_plan_day_description_3: '',
    travel_plan_day_title_4: '',
    travel_plan_day_description_4: '',
    travel_plan_day_title_5: '',
    travel_plan_day_description_5: '',
    travel_plan_day_title_6: '',
    travel_plan_day_description_6: '',
    travel_plan_day_title_7: '',
    travel_plan_day_description_7: '',
    image_gallery_1: null,
    image_gallery_2: null,
    image_gallery_3: null,
    image_gallery_4: null,
    image_gallery_5: null,
    image_gallery_6: null,
    image_gallery_7: null,
    image_gallery_8: null,
    image_gallery_9: null,
  });

  const fetchTours = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/tours`);
      if (!response.ok) throw new Error('Failed to fetch tours');
      const data = await response.json();
      setTours(data);
    } catch (error) {
      console.error('Error fetching tours:', error);
      toaster.danger('Failed to fetch tours');
    }
  };

  useEffect(() => {
    fetchTours();
  }, []);

  const handleEdit = (tour) => {
    setEditTourId(tour.id);
    setSelectedCountry(tour.country);
    setSelectedSafariType(tour.safari_type);
    setFormData({
      ...tour,
      included: tour.included || [],
      excluded: tour.excluded || [],
    });
    setIsDialogShown(true);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTagInputChange = (name, values) => {
    setFormData({ ...formData, [name]: values });
  };

  const handleImageChange = (files, name) => {
    setFormData({ ...formData, [name]: files[0] });
  };

  const handleImageDelete = async (imageField) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/tours/${editTourId}/delete-image`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ imageField }),
        }
      );

      if (!response.ok) throw new Error('Failed to delete image');
      
      setFormData({ ...formData, [imageField]: null });
      toaster.success('Image deleted successfully');
      fetchTours();
    } catch (error) {
      console.error('Error deleting image:', error);
      toaster.danger('Failed to delete image');
    }
  };

  const handleUpdate = async () => {
    const formDataToSubmit = new FormData();
    for (const key in formData) {
      if (Array.isArray(formData[key])) {
        formDataToSubmit.append(key, JSON.stringify(formData[key]));
      } else if (formData[key] !== null && formData[key] !== undefined) {
        formDataToSubmit.append(key, formData[key]);
      }
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/tours/${editTourId}`, {
        method: 'PUT',
        body: formDataToSubmit,
      });

      if (!response.ok) throw new Error('Error updating tour');

      toaster.success('Tour updated successfully!');
      setIsDialogShown(false);
      setEditTourId(null);
      resetFormData();
      fetchTours();
    } catch (error) {
      console.error('Error updating tour:', error);
      toaster.danger('Failed to update tour. Please try again.');
    }
  };

  const resetFormData = () => {
    setFormData({
      country: '',
      safari_type: '',
      destination_title: '',
      destination_name: '',
      duration: '',
      price: '',
      image_preview: null,
      info_overview: '',
      info_highlight: '',
      departure: '',
      included: [],
      excluded: [],
      travel_plan_overview: '',
      travel_plan_day_title_1: '',
      travel_plan_day_description_1: '',
      travel_plan_day_title_2: '',
      travel_plan_day_description_2: '',
      travel_plan_day_title_3: '',
      travel_plan_day_description_3: '',
      travel_plan_day_title_4: '',
      travel_plan_day_description_4: '',
      travel_plan_day_title_5: '',
      travel_plan_day_description_5: '',
      travel_plan_day_title_6: '',
      travel_plan_day_description_6: '',
      travel_plan_day_title_7: '',
      travel_plan_day_description_7: '',
      image_gallery_1: null,
      image_gallery_2: null,
      image_gallery_3: null,
      image_gallery_4: null,
      image_gallery_5: null,
      image_gallery_6: null,
      image_gallery_7: null,
      image_gallery_8: null,
      image_gallery_9: null,
    });
  };

  const handleDelete = async (tourId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/tours/${tourId}`, {
        method: 'DELETE',
      });

      if (!response.ok) throw new Error('Error deleting tour');

      toaster.success('Tour deleted successfully!');
      fetchTours();
    } catch (error) {
      console.error('Error deleting tour:', error);
      toaster.danger('Failed to delete tour. Please try again.');
    }
  };

  const filteredTours = useMemo(() => {
    return tours.filter(tour =>
      tour.destination_title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, tours]);

  const renderImageField = (fieldName, label) => (
    <Pane 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      width={250} // Fixed width for consistency
      margin={10}
    >
      {formData[fieldName] && typeof formData[fieldName] === 'string' && (
        <>
          <img
            src={`${process.env.REACT_APP_BASE_URL}/${formData[fieldName]}`}
            alt={label}
            style={{ 
              width: '250px', 
              height: '250px', 
              objectFit: 'cover', 
              marginBottom: '10px' 
            }}
          />
          <Button
            appearance="primary"
            intent="danger"
            onClick={() => handleImageDelete(fieldName)}
            marginBottom={10}
            width="100%" // Match FilePicker width
          >
            Delete Image
          </Button>
        </>
      )}
      <FilePicker
        label={formData[fieldName] ? `Replace ${label}` : label}
        name={fieldName}
        onChange={files => handleImageChange(files, fieldName)}
        accept="image/*"
        width="100%" // Match image width
      />
    </Pane>
  );

  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const galleryImages = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => ({
    fieldName: `image_gallery_${num}`,
    label: `Gallery Image ${num}`
  }));
  const galleryRows = chunkArray(galleryImages, 3);

  return (
    <div className='container_edit_tour'>
      <Heading size={600} marginBottom={16}>Manage Tours</Heading>
      <TextInput
        placeholder="Search by Destination Title"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        marginBottom={16}
      />
      <Table className="styled-table">
        <thead>
          <tr>
            <th>Destination Title</th>
            <th>Destination Name</th>
            <th>Duration</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredTours.map(tour => (
            <tr key={tour.id}>
              <td>{tour.destination_title}</td>
              <td>{tour.destination_name}</td>
              <td>{tour.duration}</td>
              <td>{tour.price}</td>
              <td>
                <Button appearance='primary' onClick={() => handleEdit(tour)}>Edit</Button>
                <Button appearance='primary' intent="danger" onClick={() => handleDelete(tour.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Dialog
        isShown={isDialogShown}
        title="Edit Tour"
        onCloseComplete={() => setIsDialogShown(false)}
        confirmLabel="Update Tour"
        onConfirm={handleUpdate}
        width="90%"
        height="95%"
      >
        <Pane padding={16}>
          <SelectMenu
            title="Select Country"
            options={['Kenya', 'Uganda', 'Tanzania'].map((label) => ({ label, value: label }))}
            selected={selectedCountry}
            onSelect={(item) => {
              setSelectedCountry(item.value);
              setFormData({ ...formData, country: item.value });
            }}
          >
            <Button>{selectedCountry || 'Select a country'}</Button>
          </SelectMenu>

          <SelectMenu
            title="Select Safari Type"
            options={['Excursions', 'Wildlife Safari', 'Cultural Safari', 'Beach Safari', 'Luxury Safari', 'Family Safari'].map((label) => ({ label, value: label }))}
            selected={selectedSafariType}
            onSelect={(item) => {
              setSelectedSafariType(item.value);
              setFormData({ ...formData, safari_type: item.value });
            }}
          >
            <Button>{selectedSafariType || 'Select a safari type'}</Button>
          </SelectMenu>

          <TextInput
            label="Destination Title"
            name="destination_title"
            value={formData.destination_title}
            onChange={handleChange}
            marginBottom={8}
            width="100%"
          />
          <TextInput
            label="Destination Name"
            name="destination_name"
            value={formData.destination_name}
            onChange={handleChange}
            marginBottom={8}
            width="100%"
          />
          <TextInput
            label="Duration"
            name="duration"
            type="number"
            value={formData.duration}
            onChange={handleChange}
            marginBottom={8}
            width="100%"
          />
          <TextInput
            label="Price"
            name="price"
            type="number"
            value={formData.price}
            onChange={handleChange}
            marginBottom={8}
            width="100%"
          />

          <Pane 
            display="flex" 
            justifyContent="center" 
            marginBottom={20}
          >
            {renderImageField('image_preview', 'Image Preview')}
          </Pane>

          <Textarea
            label="Overview"
            name="info_overview"
            value={formData.info_overview}
            onChange={handleChange}
            marginBottom={8}
            width="100%"
          />
          <Textarea
            label="Highlights"
            name="info_highlight"
            value={formData.info_highlight}
            onChange={handleChange}
            marginBottom={8}
            width="100%"
          />

          <TextInput
            label="Departure Location"
            name="departure"
            placeholder="Enter the departure location (e.g. Nairobi)"
            value={formData.departure}
            onChange={handleChange}
            width="100%"
          />

          <TagInput
            label="Included"
            inputProps={{ placeholder: 'Add items...' }}
            values={Array.isArray(formData.included) ? formData.included : []}
            onChange={(values) => handleTagInputChange('included', values)}
            marginBottom={8}
            width="100%"
          />

          <TagInput
            label="Excluded"
            inputProps={{ placeholder: 'Add items...' }}
            values={Array.isArray(formData.excluded) ? formData.excluded : []}
            onChange={(values) => handleTagInputChange('excluded', values)}
            marginBottom={8}
            width="100%"
          />

          <Textarea
            label="Travel Plan Overview"
            name="travel_plan_overview"
            placeholder="Enter an overview of the travel plan"
            value={formData.travel_plan_overview}
            onChange={handleChange}
            height={120}
            width="100%"
          />

          {[1, 2, 3, 4, 5, 6, 7].map((num) => (
            <React.Fragment key={num}>
              <TextInput
                label={`Day ${num} Title`}
                name={`travel_plan_day_title_${num}`}
                placeholder={`Enter title for Day ${num}`}
                value={formData[`travel_plan_day_title_${num}`]}
                onChange={handleChange}
                width="100%"
              />
              <Textarea
                label={`Day ${num} Description`}
                name={`travel_plan_day_description_${num}`}
                placeholder={`Enter details for Day ${num}`}
                value={formData[`travel_plan_day_description_${num}`]}
                onChange={handleChange}
                height={120}
                width="100%"
              />
            </React.Fragment>
          ))}

          <Pane marginTop={20}>
            <Heading size={500} marginBottom={16}>Gallery Images</Heading>
            {galleryRows.map((row, rowIndex) => (
              <Pane 
                key={rowIndex}
                display="flex" 
                justifyContent="space-between" 
                marginBottom={20}
                width="100%"// 3 images * (250px + 2 * 10px margin) + spacing
              >
                {row.map(({ fieldName, label }) => renderImageField(fieldName, label))}
              </Pane>
            ))}
          </Pane>
        </Pane>
      </Dialog>
    </div>
  );
}

export default TourEdit;